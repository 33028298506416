import "../scss/style.scss";
import { sliders } from "./sliders";
import { sticky } from "./sticky";

function init() {
    window.addEventListener("scroll", sticky, {passive: true});
}

sliders();
init();
