import {tns} from "tiny-slider/src/tiny-slider";
import "tiny-slider/src/tiny-slider.scss";

export function sliders() {
    let slider = document.getElementById('main-slider');

    if (slider) {
        let image = slider.querySelectorAll('.image');

        if (image.length > 1) {
            tns({
                container: '#main-slider',
                mode: 'gallery',
                loop: true,
                controls: false,
                nav: false,
                arrowKeys: false,
                items: 1,
                speed: 2000,
                autoplay: true,
                autoplayButton: false,
                autoplayButtonOutput: false,
                autoplayHoverPause: false,
                autoplayTimeout: 5000,
            });
        }
    }
}
